/* eslint-disable */

var initESW = function(gslbBaseURL) {
    embedded_svc.settings.displayHelpButton = true; //Or false
    embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'

    embedded_svc.settings.defaultMinimizedText = 'Chat with a Tire Expert'; //(Defaults to Chat with an Expert)
    //embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)

    //embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
    //embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

    // Settings for Live Agent
    //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
        // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
        // Returns a valid button ID.
    //};
    //embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
    //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
    //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)

    embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    embedded_svc.settings.entryFeature = 'LiveAgent';

    embedded_svc.settings.extraPrechatFormDetails = [{
        "label":"Visited Pages",
        "value": window.location.href,
        "transcriptFields":[ "Visited_Pages__c" ],
        "displayToAgent":true
    }];
    // Callback to fire when the help button is clicked.
    embedded_svc.addEventHandler("onHelpButtonClick", function() {
        const helpButtonClickedEvent = new Event("onHelpButtonClick", { bubbles: true, cancelable: false });
        document.dispatchEvent(helpButtonClickedEvent);
    });
    // Fired when the chat conference is initiated.
    embedded_svc.addEventHandler("onChatConferenceInitiated", function() {
        const onChatConferenceInitiatedEvent = new Event("onChatConferenceInitiated", { bubbles: true, cancelable: false });
        document.dispatchEvent(onChatConferenceInitiatedEvent);
    });
    // Fired when the agent sends a message.
    embedded_svc.addEventHandler("onAgentMessage", function() {
        const onAgentMessageEvent = new Event("onAgentMessage", { bubbles: true, cancelable: false });
        document.dispatchEvent(onAgentMessageEvent);
    });
    // Fired when the chat visitor sends a message.
    embedded_svc.addEventHandler("onChasitorMessage", function() {
        const onChasitorMessageEvent = new Event("onChasitorMessage", { bubbles: true, cancelable: false });
        document.dispatchEvent(onChasitorMessageEvent);
    });
    // Fired when the chat conference has ended.
    embedded_svc.addEventHandler("onChatRequestSuccess", function() {
        const onChatRequestSuccessEvent = new Event("onChatRequestSuccess", { bubbles: true, cancelable: false });
        document.dispatchEvent(onChatRequestSuccessEvent);
    });
    // Fired when Embedded Service Chat has ended and the application is closed.
    embedded_svc.addEventHandler("afterDestroy", function() {
        const afterDestroyEvent = new Event("afterDestroy", { bubbles: true, cancelable: false });
        document.dispatchEvent(afterDestroyEvent);
    });

    embedded_svc.init(
        'https://dealertire.my.salesforce.com',
        'https://dealertire.secure.force.com/liveAgentSetupFlow',
        gslbBaseURL,
        '00D41000000VRVx',
        'WebChatCarline',
        {
            baseLiveAgentContentURL: 'https://c.la2-c1-ord.salesforceliveagent.com/content',
            deploymentId: '5721L000000M49L',
            buttonId: '5731L000000M5ik',
            baseLiveAgentURL: 'https://d.la2-c1-ord.salesforceliveagent.com/chat',
            eswLiveAgentDevName: 'WebChatCarline2',
            isOfflineSupportEnabled: false
        }
    );
};

if (!window.embedded_svc) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://dealertire.my.salesforce.com/embeddedservice/5.0/esw.min.js');
    s.onload = function() {
        initESW(null);
    };
    document.body.appendChild(s);
} else {
    initESW('https://service.force.com');
}
